import gql from 'graphql-tag'

export const PAYMENT_METHOD_QUERY = gql`
 query paymentMethod($encryptedToken: String!) {
   paymentMethod(encryptedToken: $encryptedToken) {
     id
     intentId
     orgId
     clientSecret
     orgStripePublishableKey
   }
 }
`
