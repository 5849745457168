import { getValues } from 'myUtils/firebase'
import { useState } from 'react'
import queryString from 'query-string'
import { smsOptInStages } from 'myUtils/constants'
import { CUSTOMER_OPT_IN } from 'myUtils/mutations'
import { useMutation } from '@apollo/react-hooks'

const {
  LOADING,
  FORM,
  SENT
} = smsOptInStages

function getStyle (style) {
  try {
    const json = JSON.parse(style)
    if (typeof json === 'object') {
      return json
    }
  } catch (err) {
    return null
  }
}

const useSms = (id) => {
  const [headline, setHeadline] = useState(null)
  const [subheadline, setSubheadline] = useState(null)
  const [submitText, setSubmitText] = useState('Subscribe')
  const [logo, setLogo] = useState(null)
  const [teamName, setTeamName] = useState(null)
  const [stage, setStage] = useState(LOADING)
  const [phone, setPhone] = useState(null)
  const [disclaimer, setDisclaimer] = useState(null)
  const [buttonStyle, setButtonStyle] = useState(null)
  const [backgroundStyle, setBackgroundStyle] = useState({})
  const [organizationId, setOrganizationId] = useState(process.env.REACT_APP_ENV === 'production' ? '1999' : '1')
  const [buttonStyleDeactivated, setButtonStyleDeactivated] = useState(null)
  const [optIn] = useMutation(CUSTOMER_OPT_IN)
  const [disabledButton, setDisabledButton] = useState(false)
  const { query } = queryString.parseUrl(window.location.href)
  const {
    customer_email: customerEmail,
    k_id: kId,
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_content: utmContent,
    utm_campaign: utmCampaign,
    headline: queryHeadline,
    subheadline: querySubheadline,
    button: querySubmitText
  } = query

  const init = () => {
    const initCompany = async () => {
      try {
        let company
        if (query.id) company = query.id
        else [company] = window.location.hostname.split('.')
        const {
          logo,
          teamName,
          orgId,
          buttonStyle,
          backgroundStyle,
          buttonStyleDeactivated,
          disclaimer
        } = await getValues(`/companies/${company}`)
        if (orgId) setOrganizationId(orgId)
        if (logo) setLogo(logo)
        if (backgroundStyle) setBackgroundStyle(getStyle(backgroundStyle))
        if (teamName) setTeamName(teamName)
        if (disclaimer) setDisclaimer(disclaimer)
        if (buttonStyle) setButtonStyle(getStyle(buttonStyle))
        if (buttonStyleDeactivated) setButtonStyleDeactivated(getStyle(buttonStyleDeactivated))
        setHeadline(queryHeadline || `Text with ${teamName}`)
        setSubheadline(querySubheadline || 'Subscribe to get updates via SMS.')
        setSubmitText(querySubmitText || 'Subscribe')
        setStage(FORM)
      } catch (err) {
        console.error(err)
      }
    }
    initCompany()
  }

  const sendSms = async phone => {
    setDisabledButton(true)
    const gqlRes = await optIn({
      variables: {
        phone,
        orgId: organizationId,
        customerEmail,
        kId,
        utmSource,
        utmMedium,
        utmContent,
        utmCampaign
      }
    })
    if (gqlRes) {
      setPhone(phone)
      setStage(SENT)
    }
  }

  return {
    init,
    disabledButton,
    logo,
    headline,
    subheadline,
    submitText,
    sendSms,
    phone,
    stage,
    disclaimer,
    buttonStyle,
    backgroundStyle,
    buttonStyleDeactivated,
    teamName
  }
}

export default useSms
