export const smsOptInStages = {
  LOADING: 'LOADING',
  FORM: 'FORM',
  SENT: 'SENT'
}

export const creditCardStages = {
  LOADING: 'LOADING',
  FORM: 'FORM',
  ERROR: 'ERROR',
  CONFIRMATION: 'CONFIRMATION'
}

export const unsubscribeStages = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  UNSUBSCRIBED: 'UNSUBSCRIBED'
}
