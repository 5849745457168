import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import SmsOptIn from 'myPages/SmsOptIn'
import CreditCard from 'myPages/CreditCard'
import Unsubscribe from 'myPages/Unsubscribe'
import ErrorBoundary from 'myComponents/ErrorBoundary'

const options = {
  uri: process.env.REACT_APP_GQL_URL
}

const client = new ApolloClient({
  link: createHttpLink(options),
  cache: new InMemoryCache()
})

export default class App extends React.Component {
  render () {
    return (
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <Router>
            <Route exact path='/' component={SmsOptIn} />
            <Route exact path='/unsubscribe' component={Unsubscribe} />
            <Route exact path='/add-credit-card' component={CreditCard} />
          </Router>
        </ApolloProvider>
      </ErrorBoundary>
    )
  }
}
